import {cva} from 'class-variance-authority';

export const wrapperStyles = cva('rounded-2xl ring-0', {
  variants: {
    horizontal: {
      true: '',
      false: '',
    },
    mode: {
      light: 'ring-black/20 border-black',
      dark: 'ring-white/20 border-white',
    },
    size: {
      large: 'p-xl',
      small: 'p-lg',
    },
    type: {
      default: 'p-0',
      horizontal: 'flex flex-col sm:flex-row gap-x-lg',
      outline: 'ring-1',
      shadow: '',
      topDivider: 'p-0 pt-md border-t-2 rounded-none',
    },
  },
  compoundVariants: [
    {
      mode: 'light',
      type: 'shadow',
      className: 'shadow-light',
    },
    {
      mode: 'dark',
      type: 'shadow',
      className: 'shadow-dark',
    },
    {
      horizontal: true,
      type: 'default',
      className: 'flex flex-col sm:flex-row gap-x-lg gap-y-4 sm:gap-y-0',
    },
  ],
});

export const linkOrButtonStyles = cva('leading-[0]', {
  variants: {
    headingGroup: {
      true: 'mt-md',
      false: '',
    },
  },
});

export const imageStyles = cva('', {
  variants: {
    horizontal: {
      true: 'w-16 max-w-none',
      false: 'mb-md',
    },
  },
});

export const iconStyles = cva('w-11 h-11', {
  variants: {
    horizontal: {
      false: 'mb-md',
    },
  },
});
